export const positionArray = [
	{
		step: 'defaultPos',
		camera: {
			position: {
				x: 0,
				y: 3,
				z: 50
			},
			rotation: {
				x: 0,
				y: 0,
				z: 0
			}
		},
		duration: 1,
		duration_rotation: 0,
		ease: "Power1.easeOut"
	},
	{
		step: 'slideOut',
		camera: {
			position: {
				x: 40,
				y: 3,
				z: 50
			},
			rotation: {
				x: 0,
				y: 0,
				z: 0
			}
		},
		duration: 1,
		duration_rotation: 0,
		ease: "Power1.easeIn"
	},
	{
		step: 'slideIn',
		camera: {
			position: {
				x: -40,
				y: 3,
				z: 50
			},
			rotation: {
				x: 0,
				y: 0,
				z: 0
			}
		},
		duration: 0.1, //mi sposto velocissimo per non far vedere il cambio bottiglia
		duration_rotation: 0,
		ease: "linear"
	},
	{
		step: 'enter',
		camera: {
			position: {
				x: 19,
				y: -5.6,
				z: 28
			},
			rotation: {
				x: 0.2,
				y: 0.6,
				z: 0.6
			}
		},
		duration: 1.2,
		duration_rotation: 0,
		ease: "Power4.inOut"
	},
	{
		step: 1,
		camera: {
			position: {
				x: 19,
				y: -5.6,
				z: 28
			},
			rotation: {
				x: 0.2,
				y: 0.6,
				z: 0.6
			}
		},
		duration: 1.8,
		duration_rotation: 2.5,
		ease: "Power4.inOut"
	},
	{
		step: 2,
		camera: {
			position: {
				x: 0,
				y: -2,
				z: 26
			},
			rotation: {
				x: 0,
				y: 0,
				z: 0
			}
		},
		duration: 1.8,
		duration_rotation: 2.5,
		ease: "Power4.inOut"
	},
	{
		step: 3,
		camera: {
			position: {
				x: -9,
				y: -5.6,
				z: 17
			},
			rotation: {
				x: 0.1,
				y: -0.6,
				z: -0.5
			}
		},
		duration: 1.8,
		duration_rotation: 2.5,
		ease: "Power4.inOut"
	}
]
