import "slick-carousel";
import Splitting from "splitting";

const plus = {
	initLoad() {
		Splitting();
		this.initPlusSlider();
	},

	initPlusSlider() {
		const $el = $('.plus__slider');
		if($el.length == 0) return false

		$el.on('init', function(event, slick){
			$('.slick-slide').eq(0).addClass('active')
		});

		$el.on('afterChange', function(event, slick, currentSlide){
			$('.slick-slide').removeClass('active')
			$('.slick-slide').eq(currentSlide).addClass('active')
		});

    $el.slick({
			fade: true,
      dots: false,
      arrows: false,
      infinite: true,
      speed: 800,
      autoplay: true,
			autoplaySpeed: 8000,
      slidesToShow: 1,
      slidesToScroll: 1,
			pauseOnFocus: false,
			pauseOnHover: false
    });
	},
}

export default plus;
