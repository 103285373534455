import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader.js';
import { RoughnessMipmapper } from 'three/examples/jsm/utils/RoughnessMipmapper.js';
import Stats from 'three/examples/jsm/libs/stats.module.js';
import {positionArray} from "./positionArray"

import * as dat from 'dat.gui';

import gsap from "gsap";

import scroll from './locomotive';
import preloader from './preloader';
import animations from './animations';

let container, camera, scene, renderer, model, gui, windowHalfX, windowHalfY, mouseX, mouseY, totalDots, isAnim, requestAnimId, stats;

const exp = {
	container,
	camera,
	scene,
	renderer,
	model,
	gui,
	clock: new THREE.Clock(),
	windowHalfX: window.innerWidth / 2,
	windowHalfY: window.innerHeight / 2,
	mouseX: 0,
	mouseY: 0,
	totalDots: 0,
	isAnim: 0,
	requestAnimId,
	stats,
	positionsLimit: 3,

	initReady() {
		if($('.webgl')){
			exp.init()
			exp.tick()
			exp.initCircleEvent()
			exp.initIconEvent()
		}
	},

	timeout(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
	},

	initDebug() {
		exp.stats = new Stats();
		exp.stats.domElement.style.position = 'absolute';
		exp.stats.domElement.style.bottom = '0px';
		exp.stats.domElement.style.zIndex = 100;
		exp.container.appendChild( exp.stats.domElement );

		exp.gui = new dat.GUI({ width: 300 })
		exp.gui
		    .add(exp.model.rotation, 'y')
		    .min(-30)
		    .max(30)
		    .step(0.01)
		    .name('HDR rotation Y')
		    .listen()
		exp.gui
		    .add(exp.camera.rotation, 'x')
		    .min(-1)
		    .max(1)
		    .step(0.1)
		    .name('Camera rotation X')
		    .listen()
		exp.gui
		    .add(exp.camera.rotation, 'y')
		    .min(-1)
		    .max(1)
		    .step(0.1)
		    .name('Camera rotation Y')
		    .listen()
		exp.gui
		    .add(exp.camera.rotation, 'z')
		    .min(-1)
		    .max(1)
		    .step(0.1)
		    .name('Camera rotation Z')
		    .listen()
		exp.gui
		    .add(exp.camera.position, 'x')
		    .min(-100)
		    .max(100)
		    .step(0.1)
		    .name('Camera position X')
		    .listen()
		 exp.gui
		    .add(exp.camera.position, 'y')
		    .min(-100)
		    .max(100)
		    .step(0.1)
		    .name('Camera position Y')
		    .listen()
		exp.gui
		    .add(exp.camera.position, 'z')
		    .min(-100)
		    .max(100)
		    .step(0.1)
		    .name('Camera position Z')
		    .listen()
		exp.gui
		    .add(exp.renderer, 'toneMappingExposure')
		    .min(-10)
		    .max(10)
		    .step(0.1)
		    .name('Esposizione')
		    .listen()
	},

	loadModel(modelUrl, firstLoad) {
		return new Promise(function(resolve) {
			// model
			const roughnessMipmapper = new RoughnessMipmapper( exp.renderer );

			const loader = new GLTFLoader();
			loader.load( modelUrl, function ( gltf ) {

				exp.model = gltf.scene;

				exp.scene.add( gltf.scene );

				roughnessMipmapper.dispose();

				exp.resize();

				if(firstLoad){
					preloader.update(1, true);

					$('#main').removeClass('is-exiting');
					$('#cursor').removeClass('loading')
					scroll.update();
					setTimeout(() => {
						animations.initExpEffect()
					}, 1400);

					setTimeout(() => {
						exp.cameraMove(1)
					}, 800);

					if(window.location.hash.substr(1) == 'debug') exp.initDebug();
				}

				resolve()

			});
		})
	},

	async changeModel(model){
		// await exp.cameraMove('defaultPos')
		// await exp.timeout(250)
		await exp.cameraMove('slideOut')

		//Rimuovo oggetto caricato
		exp.scene.remove(exp.model);

		await exp.cameraMove('slideIn')
		await exp.loadModel(model)
		// await exp.cameraMove('defaultPos')
		// await exp.timeout(250)
		await exp.cameraMove($('.circle__wrap').data('step'))
	},

	init() {
		exp.container = document.querySelector('.webgl')

		exp.camera = new THREE.PerspectiveCamera( 45, window.innerWidth / window.innerHeight, 10, 1000 );
		exp.camera.setFocalLength = 135;
		exp.camera.position.set( 0, 3, 50);

		exp.scene = new THREE.Scene();

		new RGBELoader()
			.setDataType( THREE.UnsignedByteType )
			.load( '/three/studio.hdr', function ( texture ) {

				preloader.update(.5);

				const envMap = pmremGenerator.fromEquirectangular( texture ).texture;
				// exp.scene.background = envMap;
				exp.scene.environment = envMap;

				texture.dispose();

				pmremGenerator.dispose();

				exp.loadModel('/three/Terrecevico_Sangiovese.gltf', true)

			});

		exp.renderer = new THREE.WebGLRenderer( { preserveDrawingBuffer: true, alpha: true, powerPreference: 'high-performance'} );
		exp.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
		exp.renderer.setSize( window.innerWidth, window.innerHeight );
		exp.renderer.toneMapping = THREE.ACESFilmicToneMapping;
		exp.renderer.toneMappingExposure = 2;
		exp.renderer.outputEncoding = THREE.sRGBEncoding;
		exp.container.appendChild( exp.renderer.domElement );

		const pmremGenerator = new THREE.PMREMGenerator( exp.renderer );
		pmremGenerator.compileEquirectangularShader();

		if(window.location.hash.substr(1) == 'debug'){
			const controls = new OrbitControls( exp.camera, exp.renderer.domElement );
			controls.addEventListener( 'change', exp.render ); // use if there is no animation loop
			controls.update();
		}

		if($(window).outerWidth() > 767){
			window.addEventListener( 'resize', exp.resize );
		}

		if (window.DeviceOrientationEvent && 'ontouchstart' in window) {
		    window.addEventListener("deviceorientation", exp.gyroMove)
		}else {
			document.addEventListener( 'mousemove', exp.onDocumentMouseMove )
		}
	},

	resize() {
		exp.camera.aspect = window.innerWidth / window.innerHeight;
		exp.camera.updateProjectionMatrix();

		exp.renderer.setSize( window.innerWidth, window.innerHeight );
		exp.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

		exp.render();
	},

	render() {
		exp.renderer.render( exp.scene, exp.camera );

		if (window.DeviceOrientationEvent && 'ontouchstart' in window) {
			if ( exp.model ) {
				exp.model.rotation.x = exp.mouseX;
				exp.model.rotation.y = exp.mouseY;
			}
		} else {
			let eventX = 0;
			let eventY = 0;
			const windowLimitW = $(window).outerWidth() * 20 / 100;
			const windowLimitH = $(window).outerHeight() * 20 / 100;

			if(exp.mouseX >= windowLimitW){
				eventX = windowLimitW
			} else if(exp.mouseX <= windowLimitW*-1){
				eventX = windowLimitW * -1
			} else {
				eventX = exp.mouseX
			}

			if(exp.mouseY >= windowLimitH){
				eventY = windowLimitH
			} else if(exp.mouseY <= windowLimitH*-1){
				eventY = windowLimitH * -1
			} else {
				eventY = exp.mouseY
			}

			exp.targetX = eventX * .0008;
			exp.targetY = eventY * .0008;

			if ( exp.model && !exp.isAnim) {
				exp.model.rotation.y += 0.02 * ( exp.targetX - exp.model.rotation.y );
				exp.model.rotation.x += 0.02 * ( exp.targetY - exp.model.rotation.x );
			}
		}
	},

	onDocumentMouseMove( event ) {
		exp.mouseX = ( event.clientX - exp.windowHalfX );
		exp.mouseY = ( event.clientY - exp.windowHalfY );
	},

	gyroMove(event) {
		let x = event.beta;  // In degree in the range [-180,180)
  	let y = event.gamma; // In degree in the range [-90,90)

		x -= 45;

		exp.targetX = x * .004
		exp.targetY = y * .004

		exp.mouseX = exp.targetX
		exp.mouseY = exp.targetY
	},

	cameraMove(step, callback, model) {
		return new Promise(function(resolve) {
			positionArray.forEach(function (arrayItem) {
					if(arrayItem.step == step){
						const tl = gsap.timeline({
							onComplete: () => {
								exp.isAnim = 0

								resolve()

								if(arrayItem.step != 'enter' && arrayItem.step != 'defaultPos' && arrayItem.step != 'slideOut' && arrayItem.step != 'slidein'){
									//Riporto la rotazione a 0 per evitare scazzi nell' update della posizione con il mousemove
									exp.model.rotation.y = 0;
								}

								if(callback && typeof callback == 'function') callback(model);

							}
						})
						tl.addLabel('start')
						tl.to(exp.camera.position, {
							x: arrayItem.camera.position.x,
							y: arrayItem.camera.position.y,
							z: arrayItem.camera.position.z,
							duration: arrayItem.duration, ease: arrayItem.ease}, 'start')
						tl.to(exp.camera.rotation, {
							x: arrayItem.camera.rotation.x,
							y: arrayItem.camera.rotation.y,
							z: arrayItem.camera.rotation.z,
							duration: arrayItem.duration, ease: arrayItem.ease}, 'start')

						if(arrayItem.step != 'enter' && arrayItem.step != 'defaultPos' && arrayItem.step != 'slideOut' && arrayItem.step != 'slidein'){
							tl.to(exp.model.rotation, {y: `-=${Math.PI * 2}`, duration: arrayItem.duration_rotation, ease: arrayItem.ease}, 'start')
						}
					}
			});
		})
	},

	tick() {
		if(exp.stats) exp.stats.begin();

		// Render
		exp.render();

		if(exp.stats) exp.stats.end();

		exp.requestAnimId = window.requestAnimationFrame(exp.tick)
	},

	removeTick() {
		window.cancelAnimationFrame(exp.requestAnimId);
	},

	initCircleEvent() {

		$('.circle__wrap').click(function() {
			if(exp.isAnim) return false;
			exp.isAnim = 1

			let current = $('.circle__wrap').data('step')
			const next  = (current++ >= exp.positionsLimit) ? 1 : current++
			$('.circle__wrap').data('step', next)

			//  Animazioni label punto interattivo
			const allLabel = document.querySelectorAll('.circle__title__label .word')
			gsap.timeline().to(allLabel, {yPercent: 100, opacity: 0, duration: .3})
			const currentLabel = $(`.circle__title__label[data-step="${next}"] .word`)
			gsap.timeline().to(currentLabel, {yPercent: 0, opacity: 1, duration: .7, stagger: { from: "start", each: 0.2, ease: 'Power2.Out' }, delay: .3 }, 'start')

			exp.cameraMove(next)
		})
	},

	initIconEvent() {
		$('.icon').on('click', function(){
			if(exp.isAnim) return false;
			exp.isAnim = 1

			$('.icon').removeClass('active')
			$(this).addClass('active')

			const model = $(this).data('model');
			exp.changeModel(model)
		})
	}
}

export default exp;
