import gsap from "gsap";
import scroll from './locomotive';

const scrActions = {
	initLoad() {
		this.scrollTrigger();
	},

	detectAnim(args) {
		scrActions.navAnim(args);
	},

	scrollTrigger(){
		if(scroll.loc){
			const position = scroll.loc.on("scroll", args => {
				scrActions.detectAnim(args);
			});
		}
  },

	navAnim(args) {
		const $sideNav = $('.sideNav')
		const $onlyHome = $('.visual-experience')

		if($sideNav.length && $onlyHome.length){
			Object.keys(args.currentElements).forEach(function (item) {
				const current = args.currentElements[item];
				if(current.el.classList.contains('section-trigger')){
					const $el = $(current.el)
					const id = $($el).attr('id')

					$(`.sideNav li`).removeClass('active')
					$(`.sideNav a[data-target="#${id}"]`).parent().addClass('active')

					/* Faccio vedere la label in basso a destra */
					const $fixedLabel = $('.currentSection')
					const currentLabel = $(`.sideNav a[data-target="#${id}"] span`).text()

					$fixedLabel.html(currentLabel)
				}
			});
		}
	}
}

export default scrActions;
