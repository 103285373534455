import LocomotiveScroll from "locomotive-scroll";

const scroll = {
  loc: "",
  initReady() {
    scroll.init();
  },
  init() {
    scroll.loc = new LocomotiveScroll({
      el: document.querySelector("[data-scroll-container]"),
      smooth: true,
      direction: "vertical",
      lerp: 0.1,
      draggingClass: "has-scroll-dragging",
      scrollbarContainer: document.querySelector("body"),
      tablet: {
        smooth: true,
        direction: "vertical",
        gestureDirection: "vertical",
      },
      smartphone: {
        smooth: false,
      },
    });
  },
  update() {
    if(scroll.loc) scroll.loc.update();
  },
  goTo(target, options) {
    scroll.loc.scrollTo(target, options);
  },
};
export default scroll;
