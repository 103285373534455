const cursor = {
	cursorEl: document.querySelector('#cursor'),
	cursorCircle: document.querySelector('.cursor__circle'),
	// cursorModifiers: document.querySelectorAll('[cursor-class]'),

	mouse: { x: -100, y: -100 }, // mouse pointer's coordinates
	pos: { x: 0, y: 0 }, // cursor's coordinates
	speed: 0.2, // between 0 and 1

	updateCoordinates(e) {
		cursor.mouse.x = e.clientX;
		cursor.mouse.y = e.clientY;
	},

	getAngle(diffX, diffY) {
		return Math.atan2(diffY, diffX) * 180 / Math.PI;
	},

	getSqueeze(diffX, diffY) {
		const distance = Math.sqrt(
			Math.pow(diffX, 2) + Math.pow(diffY, 2)
		);
		const maxSqueeze = 0.25;
		const accelerator = 750;
		return Math.min(distance / accelerator, maxSqueeze);
	},

	updateCursor() {
		const diffX = Math.round(cursor.mouse.x - cursor.pos.x);
		const diffY = Math.round(cursor.mouse.y - cursor.pos.y);

		cursor.pos.x += diffX * cursor.speed;
		cursor.pos.y += diffY * cursor.speed;

		const angle = cursor.getAngle(diffX, diffY);
		const squeeze = cursor.getSqueeze(diffX, diffY);

		const scale = 'scale(' + (1 + squeeze) + ', ' + (1 - squeeze) +')';
		const rotate = 'rotate(' + angle +'deg)';
		const translate = 'translate3d(' + cursor.pos.x + 'px ,' + cursor.pos.y + 'px, 0)';

		cursor.cursorEl.style.transform = translate;
		cursor.cursorCircle.style.transform = rotate + scale;
	},

	loop() {
		cursor.updateCursor();
		requestAnimationFrame(cursor.loop);
	},

	loadModifiers() {
		$(document).on('mouseenter', '[cursor-class]', function(){
			const className = $(this).attr('cursor-class');
			cursor.cursorEl.classList.add(className);
		})
		$(document).on('mouseleave', '[cursor-class]', function(){
			const className = $(this).attr('cursor-class');
			cursor.cursorEl.classList.remove(className);
		})
		$(document).on('mouseenter', 'a', function(){
			const className = 'default';
			cursor.cursorEl.classList.add(className);
		})
		$(document).on('mouseleave', 'a', function(){
			const className = 'default';
			cursor.cursorEl.classList.remove(className);
		})
	},

	initReady() {
		$(cursor.cursorEl).addClass('active');
		cursor.loadModifiers();
		window.addEventListener('mousemove', cursor.updateCoordinates);
		requestAnimationFrame(cursor.loop);
	},

	destroy() {
		$(cursor.cursorEl).remove();
	}
}

export default cursor;