import $ from 'jquery'
import '../sass/main.scss';
import utils from './utils/utils';
import preloader from './preloader';
import scroll from './locomotive';
import smoothState from './smoothState';
import cursor from './cursor';
import animations from './animations';
import exp from './visual-experience';
import prod_exp from './prod-experience';
import scrActions from './scroll-actions';
import menu from './menu';
import video from './video';
import plus from './plus';
import wines from './wines';

window.$ = require('jquery');
window.jQuery = require('jquery');

const siteGlobal = {
  initReady() {
    scroll.initReady();
    scroll.goTo('#top');
    utils.initReady();
    smoothState.initReady();
    if($('.visual-experience').length){
      $('.visual-experience').height($(window).outerHeight());
      exp.initReady();
    }
    if($('.prod-webgl').length){
      prod_exp.initReady();
    }
    if(!utils.is_touch_device()) cursor.initReady()
    else cursor.destroy();

    video.initReady();
  },
  initLoad() {
    scrActions.initLoad();
    animations.initLoad();
    menu.initLoad();
    plus.initLoad();
    wines.initLoad();

    const timer = ($('.page-template-default').length) ? 2000 : 100
    setTimeout(() => {
      scroll.update();
    }, timer);
  },
  rebuildAllEvents() {
    utils.initReady();
    scrActions.initLoad();
    animations.initLoad();
    menu.initLoad();
    video.initReady();
    plus.initLoad();
    wines.initLoad();

    if($('.visual-experience').length){
      $('.visual-experience').height($(window).outerHeight());
      exp.initReady();
    }
    if($('.prod-webgl').length){
      prod_exp.initReady();
    }

    const timer = ($('.page-template-default').length) ? 2000 : 100
    setTimeout(() => {
      scroll.update();
    }, timer);
  },
  initScroll( ){
  },
  initResize() {
    if($(window).outerWidth() > 767){
      if($('.visual-experience').length) $('.visual-experience').height($(window).outerHeight());
    }
    scroll.update();
  },
  rebuild() {
    siteGlobal.rebuildAllEvents();
  },
};

$(document).ready(() => {
  siteGlobal.initReady();
});

$(document).on('rebuild', () => {
  siteGlobal.rebuild();
});

$(window).on('load', () => {
  if($('.page-template-page_HOME').length <= 0 && $('.page-template-page_PRODUCT').length <= 0) preloader.update(1, true);
  siteGlobal.initLoad();
});

$(window).on('resize', () => {
  siteGlobal.initResize();
});

$(window).on('scroll', () => {
  siteGlobal.initScroll();
});
