import smoothState from 'smoothState';
import scroll from './locomotive';
import exp from './visual-experience';
import prod_exp from './prod-experience';

const pageTransition = {
	smooth: '',

	initReady() {
		this.initPageTransition();
	},

	initPageTransition() {
		const options = {
			anchors: 'a',
			forms: 'form',
			allowFormCaching: false,
			scroll: true,
			blacklist: '.no-smoothState , .no-smoothState > a, .wpcf7-form',
			prefetch: false,
			cacheLength: 0,
			onBefore: function($currentTarget, $container) {
				if($('.visual-experience').length) exp.removeTick()
				if($('.prod-webgl').length) prod_exp.removeTick()
			},
			onStart: {
				duration: 400,
				render: function ($container) {
					$('#main').addClass('is-exiting');
					$('#cursor').attr('class', 'active loading')

					setTimeout(() => {
						scroll.goTo(0,{
							duration: 0
						});
					}, 350)
				}
			},
			onReady: {
				render: function ($container, $newContent) {
						$container.html($newContent);
				}
			},
			onAfter: function($container, $newContent){

				$(document).trigger('rebuild');

				var cf_selector = 'div.wpcf7 > form';
				var cf_forms = $newContent.find(cf_selector);
				if (cf_forms.length>0) {
					$(cf_selector).each(function() {
						var $form = $(this);
						wpcf7.init( $form[0] );
					});
				}

				setTimeout(function(){
					if($newContent.find('.visual-experience').length <= 0 && $newContent.find('.prod-webgl').length <= 0){
						$('#main').removeClass('is-exiting');
						$('#cursor').removeClass('loading')
					}
				}, 150);
			}
		};

		pageTransition.smooth = $('#main').smoothState(options).data('smoothState');
  },

	load(url){
		pageTransition.smooth.load(url)
	}
}

export default pageTransition;
