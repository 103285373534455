import { map, constrain, lerp } from './math';

export default class Observer {
  constructor(opts) {
    this.opts = opts;

    [this.el] = this.opts.el;
    this.$els = {};
    this.$els.el = this.opts.el;

    this.scroll = window.mainScrollbar || null;

    this.bindEvent();
    this.init();
  }

  bindEvent() {
    if (this.scroll) {
      this.scroll.addListener((s) => { this.onScroll(s); });
    } else {
      document.addEventListener('scroll', (event) => { this.onScroll(event); });
    }
  }

  /* Handlers
  --------------------------------------------------------- */
  onScroll() {
    const observer = this;
    const offset = map(observer.opts.offset || 0, -1, 1, -window.innerHeight, window.innerHeight);

    this.$els.el.each(function () {
      const $el = $(this);
      if (this.getBoundingClientRect().top <= window.innerHeight - offset && this.getBoundingClientRect().bottom + offset >= 0 && !$el.hasClass('in-view')) {
        if (!$el.hasClass('has-entered') && observer.opts.onEnter) observer.opts.onEnter($(this));
        $el.addClass('has-entered');
        $el.addClass('in-view');
      }

      if (this.getBoundingClientRect().bottom < 0 || this.getBoundingClientRect().top > window.innerHeight) {
        if (observer.opts.onExit) {
          observer.opts.onExit($(this));
          $el.removeClass('in-view');
        }
      }
    });
  }

  /* Values
  --------------------------------------------------------- */

  /* Actions
  --------------------------------------------------------- */

  init() {
    this.onScroll();
  }

  /* Values
  --------------------------------------------------------- */

}
