import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader.js';
import { RoughnessMipmapper } from 'three/examples/jsm/utils/RoughnessMipmapper.js';
import Stats from 'three/examples/jsm/libs/stats.module.js';

import * as dat from 'dat.gui';

import gsap from "gsap";

import scroll from './locomotive';
import preloader from './preloader';
import animations from './animations';

let container, camera, scene, renderer, model, gui, requestAnimId, stats, controls;

const exp = {
	container,
	camera,
	scene,
	renderer,
	model,
	gui,
	clock: new THREE.Clock(),
	requestAnimId,
	stats,
	controls,

	initReady() {
		if($('.prod-webgl')){
			exp.init()
			exp.tick()
		}
	},

	initDebug() {
		exp.stats = new Stats();
		exp.stats.domElement.style.position = 'absolute';
		exp.stats.domElement.style.bottom = '0px';
		exp.stats.domElement.style.zIndex = 100;
		exp.container.appendChild( exp.stats.domElement );

		exp.gui = new dat.GUI({ width: 300 })
		exp.gui
		    .add(exp.model.rotation, 'y')
		    .min(-30)
		    .max(30)
		    .step(0.01)
		    .name('HDR rotation Y')
		    .listen()
		exp.gui
		    .add(exp.camera.rotation, 'x')
		    .min(-1)
		    .max(1)
		    .step(0.1)
		    .name('Camera rotation X')
		    .listen()
		exp.gui
		    .add(exp.camera.rotation, 'y')
		    .min(-1)
		    .max(1)
		    .step(0.1)
		    .name('Camera rotation Y')
		    .listen()
		exp.gui
		    .add(exp.camera.rotation, 'z')
		    .min(-1)
		    .max(1)
		    .step(0.1)
		    .name('Camera rotation Z')
		    .listen()
		exp.gui
		    .add(exp.camera.position, 'x')
		    .min(-100)
		    .max(100)
		    .step(0.1)
		    .name('Camera position X')
		    .listen()
		 exp.gui
		    .add(exp.camera.position, 'y')
		    .min(-100)
		    .max(100)
		    .step(0.1)
		    .name('Camera position Y')
		    .listen()
		exp.gui
		    .add(exp.camera.position, 'z')
		    .min(-100)
		    .max(100)
		    .step(0.1)
		    .name('Camera position Z')
		    .listen()
		exp.gui
		    .add(exp.renderer, 'toneMappingExposure')
		    .min(-10)
		    .max(10)
		    .step(0.1)
		    .name('Esposizione')
		    .listen()
	},

	init() {
		const model = $('#3d_model').data('3d');
		exp.container = document.querySelector('.prod-webgl')
		exp.camera = new THREE.PerspectiveCamera( 45, exp.container.offsetWidth / exp.container.offsetHeight, 10, 1000 );
		exp.camera.setFocalLength = 135;
		exp.camera.position.set( 0, 2, 45);

		exp.scene = new THREE.Scene();

		new RGBELoader()
			.setDataType( THREE.UnsignedByteType )
			.load( '/three/studio.hdr', function ( texture ) {

				preloader.update(.5);

				const envMap = pmremGenerator.fromEquirectangular( texture ).texture;
				// exp.scene.background = envMap;
				exp.scene.environment = envMap;

				texture.dispose();

				pmremGenerator.dispose();

				// model
				const roughnessMipmapper = new RoughnessMipmapper( exp.renderer );

				const loader = new GLTFLoader();
				loader.load( model, function ( gltf ) {

					exp.model = gltf.scene;

					exp.scene.add( gltf.scene );

					roughnessMipmapper.dispose();

					exp.resize();

					preloader.update(1, true);

					$('#main').removeClass('is-exiting');
					$('#cursor').removeClass('loading')
					scroll.update();

					exp.camera.position.set( 0, 2, 45);

					setTimeout(() => {
						animations.initHeaderAnim()
					}, 1000);

					if(window.location.hash.substr(1) == 'debug') exp.initDebug();

				} );

			});

		exp.renderer = new THREE.WebGLRenderer( { preserveDrawingBuffer: true, alpha: true, powerPreference: 'high-performance'} );
		exp.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
		exp.renderer.setSize( exp.container.offsetWidth, exp.container.offsetHeight );
		exp.renderer.toneMapping = THREE.ACESFilmicToneMapping;
		exp.renderer.toneMappingExposure = 2;
		exp.renderer.outputEncoding = THREE.sRGBEncoding;
		exp.container.appendChild( exp.renderer.domElement );

		const pmremGenerator = new THREE.PMREMGenerator( exp.renderer );
		pmremGenerator.compileEquirectangularShader();

		exp.controls = new OrbitControls( exp.camera, exp.renderer.domElement );
		exp.controls.autoRotate = true;
		exp.controls.autoRotateSpeed *= -2;
		exp.controls.enableDamping = true;
		exp.controls.minPolarAngle = Math.PI/2;
		exp.controls.maxPolarAngle = Math.PI/2;
		exp.controls.enableZoom = false;
		exp.controls.enablePan = false;
		// Dato che il modello è leggermente scentrato in Y, creo un nuovo target per l'orbit control
		exp.controls.target = new THREE.Vector3(0, 2, 0)

		if($(window).outerWidth() > 767){
			window.addEventListener( 'resize', exp.resize );
		}
	},

	resize() {
		exp.camera.aspect = exp.container.offsetWidth / exp.container.offsetHeight;
		exp.camera.updateProjectionMatrix();

		exp.renderer.setSize( exp.container.offsetWidth, exp.container.offsetHeight );
		exp.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

		exp.render();
	},

	render() {
		exp.renderer.render( exp.scene, exp.camera );
		exp.controls.update();
	},

	tick() {
		if(exp.stats) exp.stats.begin();

		// Render
		exp.render();

		if(exp.stats) exp.stats.end();

		exp.requestAnimId = window.requestAnimationFrame(exp.tick)
	},

	removeTick() {
		window.cancelAnimationFrame(exp.requestAnimId);
	},
}

export default exp;
