import gsap from "gsap";
import Observer from './utils/observer';

const menu = {

	initLoad() {
		this.initSideNav()
	},

	initSideNav() {
		const $el = $('.sideNav')
		const $onlyHome = $('.visual-experience')
		if($onlyHome.length == 0) return false

		/* COMPOSIZIONE */
		const $sections = $('section')
		Array.prototype.forEach.call($sections, child => {
			const label = $(child).data('title')
			const refLink = $(child).attr('id')

			if(label){
				const linkHtml = `
					<li>
						<a class="goToLink" data-target="#${refLink}" href="javascript:;" title=""><span>${label}</span><i></i></a>
					</li>
				`
				$el.find('ul').append(linkHtml)
			}
		});

		/* ANIMAZIONE DI TUTTI I BULLET*/
		// const $sideNavEls = $('.sideNav a span')
		// const hover = gsap.to($sideNavEls, {x: 0, opacity: 1, duration: .6, paused: true, stagger: { each: 0.1, ease: 'Power3.inOut' }})

		// $el.on("mouseenter", () => hover.play());
		// $el.on("mouseleave", () => hover.reverse());

		/* ANIMAZIONE DEI SINGOLI BULLET */
		$('.sideNav li').on("mouseenter", function() {
			gsap.to($('span', this), {x: 0, opacity: 1, duration: .6, ease: 'Power3.inOut'})
		});
		$('.sideNav li').on("mouseleave", function() {
			gsap.to($('span', this), {x: 10, opacity: 0, duration: .6, ease: 'Power3.inOut'})
		});

	}
}

module.exports = menu;
