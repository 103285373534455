import gsap from "gsap";

const preloader = {
	$preloaderWrap: $('.preloader'),
	$preloader: $('.preloader__progress__hover'),

	initReady() {
		this.initLoading();
	},

	update(step, resolve){
		gsap.timeline({
				onComplete: () => {
					if(resolve){
						preloader.$preloaderWrap.addClass('loaded')
					}
				}
			})
    		.to(preloader.$preloader, {scaleX: step, duration: 1, ease: "none" })
	},

	initLoading() {
		gsap
			.to(preloader.$preloader, {scaleX: .2, duration: 1, ease: "none" })
	},
}

export default preloader;