import Observer from './observer';
import scroll from '../locomotive';

const utils = {
	ins: [],

	initReady() {
		utils.goToPos()
		utils.bindScrollingLink()
		utils.manageEffectOnViewport()
	},
	goToPos: (ref, offset = 0) => {
		const $ref = (typeof ref === 'string') ? $(ref) : ref;

		if ($(ref).length === 0) return false;
		scroll.goTo(ref);
	},

  bindScrollingLink() {
		$(document).on('click', '.goToLink', function () {
			const target = $(this).data('target');

			if (typeof target !== 'undefined') {
				utils.goToPos(target);
			}
		});
	},
	is_touch_device() {
		const prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');
		const mq = function (query) {
			return window.matchMedia(query).matches;
		};

		if (('ontouchstart' in window) || window.DocumentTouch && document instanceof DocumentTouch) {
			return true;
		}

		// include the 'heartz' as a way to have a non matching MQ to help terminate the join
		// https://git.io/vznFH
		const query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('');
		return mq(query);
	},
	manageEffectOnViewport: () => {
		const $els = $('.obs');

		$els.each(function (i, el) {
			const offset = $(el).data('offset') || 0.5;
			utils.ins.push(new Observer({
				el: $(el),
				onEnter: ($el) => {
					const delay = $el.data('delay') || 0;
					// $el.css('transitionDelay', `${delay}s`);
				},
				onExit: null,
				offset
			}));
		});
	},
}

export default utils;
