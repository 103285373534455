import { gsap } from 'gsap';

const animations = {
	initLoad() {
		if(!$('.visual-experience').length) this.initHeaderAnim()
	},

	initExpEffect() {
		const $el = $('.visual-experience');
		if($el.length == 0) return false

		const logo = document.querySelector('.header__logo')
		const menu = document.querySelector('.header__lang')
		const title = document.querySelectorAll('.visual-experience__content__title .word')
		const navigation = document.querySelector('.sideNav')
		const circles = document.querySelectorAll('.circles-small .circle')
		const scrollHint = document.querySelector('.scroll-down')
		const currentLabel = $(`.circle__title__label[data-step="1"] .word`)
		const icons = document.querySelectorAll('.icon')

		gsap.timeline()
		.addLabel('start')
		.to(logo, {y: 0, opacity: 1, duration:.75, ease: 'Power3.inOut'}, 'start')
		.to(menu, {y: 0, opacity: 1, duration:.75, ease: 'Power3.inOut'}, 'start')
		.to(title, {y: 0, opacity: 1, duration: 1, stagger: { from: "start", each: 0.3, ease: 'Power2.Out' }, delay: .6 }, 'start')
		.to(navigation, {x: 0, opacity: 1, duration:.75, ease: 'Power3.inOut'}, 'start')
		.to(circles, {opacity: 1, duration:.75, ease: 'Power3.inOut'}, 'start')
		.to(scrollHint, {x: 0, opacity: 1, duration:.75, ease: 'Power3.inOut'}, 'start')
		.to(currentLabel, {yPercent: 0, opacity: 1, duration: .7, stagger: { from: "start", each: 0.2, ease: 'Power2.Out' }, delay: .3 }, 'start')
		.to(icons, {opacity: 1, duration: .7, stagger: { from: "start", each: 0.2, ease: 'Power2.Out' }, delay: .5 }, 'start')
	},

	initHeaderAnim(){
		const logo = document.querySelector('.header__logo')
		const menu = document.querySelectorAll('.header__lang')

		gsap.timeline({delay: .5})
		.addLabel('start')
		.to(logo, {y: 0, opacity: 1, duration:.75, ease: 'Power3.inOut'}, 'start')
		.to(menu, {y: 0, opacity: 1, duration:.75, ease: 'Power3.inOut'}, 'start')
	}
}

export default animations;
