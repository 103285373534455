const video = {
	initReady() {
		this.initVideo();
	},

	initVideo() {
		const $el = $('#video video');
		if($el.length) {
			const srcD = $el.data('src');
			$el.find('source').attr('src', srcD);
			$el.attr('preload', 'auto')
			$el[0].load()
			// $el[0].play()
		}

		const $el2 = $('#visual-experience__video');
		if($el2.length) {
			const srcD = $el2.data('src');
			$el2.find('source').attr('src', srcD);
			$el2.attr('preload', 'auto')
			$el2[0].load()
			// $el2[0].play()
		}
	},
}

export default video;
