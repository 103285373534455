// export const map = (value, min1, max1, min2, max2) => min2 + (max2 - min2) * (value - min1) / (max1 - min1)
const map = (n, start1, stop1, start2, stop2) => {
  const newval = ((n - start1) / (stop1 - start1)) * (stop2 - start2) + start2;
  return Math.max(Math.min(newval, stop2), start2);
};

const constrain = (n, start2, stop2) => Math.max(Math.min(n, stop2), start2);

const lerp = (a, b, n) => (1 - n) * a + n * b;

const calcWinsize = () => ({ width: window.innerWidth, height: window.innerHeight });

const distance = (x1, y1, x2, y2) => {
  const a = x1 - x2;
  const b = y1 - y2;

  return Math.hypot(a, b);
};

const getRandomFloat = (min, max) => (Math.random() * (max - min) + min).toFixed(2);

export {
  map,
  constrain,
  lerp,
  calcWinsize,
  distance,
  getRandomFloat
};
