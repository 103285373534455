import smoothState from './smoothState';
import "slick-carousel";
import Xwiper from 'xwiper';

const wines = {
	index: 0,
	limit: $('.wine-list__slide').length,
	time: 5000,
	interval: '',

	initLoad() {
		if(wines.interval != '') wines.clear()
		this.initWinesSlider();
	},

	setInterval(){
		wines.interval = setInterval(() => {
			wines.index++
			if(wines.index >= wines.limit) wines.index = 0;

			wines.setActiveEl(wines.index)

		}, wines.time);
	},

	clear() {
		wines.index = 0
		clearInterval(wines.interval);
	},

	setActiveEl(index){
		$('.wine-list__slider').attr('data-start', index);
		$('.wine-list__slide').removeClass('active')
		$('.wine-list__slide').eq(index).addClass('active')

		$('.wine-list__slide').attr('cursor-class','default');
		// $('.wine-list__slide').eq(index).removeAttr('cursor-class');

		$('.wine-list__bullet').removeClass('active')
		$('.wine-list__bullet').eq(index).addClass('active');
	},

	initWinesSlider() {
		const $el = $('.wine-list__slider');
		if($el.length == 0) return false

		const xwiper = new Xwiper('.wine-list__slider');

		$('.wine-list__slide').eq(wines.index).addClass('active')
		$('.wine-list__slide').attr('cursor-class','default');
		// $('.wine-list__slide').eq(wines.index).removeAttr('cursor-class');
		$('.wine-list__bullet').removeClass('active')
		$('.wine-list__bullet').eq(wines.index).addClass('active');

		wines.setInterval();

		$('.wine-list__slide').on('click', function(){
			if(!$(this).hasClass('active')){
				clearInterval(wines.interval);
				wines.setInterval();

				const currentIndex = $(this).index();
				wines.index = currentIndex

				wines.setActiveEl(wines.index)
			} else {
				const link = $(this).data('link')
				smoothState.load(link)
			}
		})

		$('.wine-list__bullet').on('click', function(){
			if(!$(this).hasClass('active')){
				clearInterval(wines.interval);
				wines.setInterval();

				const currentIndex = $(this).index();
				wines.index = currentIndex

				wines.setActiveEl(wines.index)
			}
		})

		xwiper.onSwipeLeft(function(){
			clearInterval(wines.interval);
			wines.setInterval();

			wines.index++
			if(wines.index >= wines.limit) wines.index = 0;

			wines.setActiveEl(wines.index)
		});
		xwiper.onSwipeRight(function(){
			clearInterval(wines.interval);
			wines.setInterval();

			wines.index--
			if(wines.index < 0) wines.index = $('.wine-list__slide').length - 1;

			wines.setActiveEl(wines.index)
		});
	},
}

export default wines;
